import "../styles/home.css"
import heroImage from "../images/sunset_deck.webp"
import fireImage from "../images/fire.jpg"
import goatImage from "../images/goats.jpg"
import { Button } from '@mui/material';
import { Mail } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#344554',
          color: '#EFF2F6'
        },
      },
    },
  },
});

function Home() {
  const navigate = useNavigate();

  const toContact = () => {
		navigate('/contact');
	};

  return (
    <div className='container'>
      <div className="image-container">
        <img src={heroImage} alt="heloo" />
      </div>
      <div className="blurb">
        <h1>Coastal Escape</h1>
        <p>Immerse yourself in Kaikoura’s natural beauty with a stay at our cozy retreat. Take in the stunning views from the mountains to the sea while you relax in the covered outdoor seating and appreciate the local wildlife.</p>
      </div>
      <div className="image-container">
        <img src={goatImage} alt="heloo" />
      </div>
      <div className="blurb">
        <h1>Space & Comfort</h1>
        <p>Comfortably sleeps six with 3 bedrooms (1 super king, 2 queens), 2 bathrooms, and a cozy fire that heats the entire house. Outside the house unwind in our fire-heated bath or fire up BBQ for a delicious feast.</p>
      </div>
      <div className="image-container">
        <img src={fireImage} alt="heloo" />
      </div>
      <div className="contact-container">
        <ThemeProvider theme={theme}>
          <Button onClick={toContact} Button variant="contained" startIcon={<Mail/>}>
            Contact
          </Button>
        </ThemeProvider>
      </div>
    </div>
  );
}

export default Home;
