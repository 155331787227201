import './styles/app.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useState, useEffect } from 'react'
import Header from './components/Header.jsx'
import Footer from './components/Footer.jsx'
import Home from './pages/Home.jsx'
import Gallery from './pages/Gallery.jsx'
import Reviews from './pages/Reviews.jsx'
import Contact from './pages/Contact.jsx'
import ContactSuccess from './pages/ContactSuccess.jsx'

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 991px)');
    setIsMobile(mediaQuery.matches);

    const handleMediaQueryChange = (event) => {
      setIsMobile(event.matches);
    };

    mediaQuery.addEventListener('change', handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);
  return (
  <BrowserRouter>
    <div className="app">
      <Header/>
      <div className="route-container">
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/gallery" element={<Gallery isMobile={isMobile} />} />
          <Route path="/reviews" element={<Reviews/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/contact-success" element={<ContactSuccess />} />
        </Routes>
      </div>
      <Footer/>
    </div>
  </BrowserRouter>
);
}

export default App;
