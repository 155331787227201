import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/Loading';
import { Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Send } from '@mui/icons-material';
import '../styles/contact.css';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#344554',
          color: '#EFF2F6'
        },
      },
    },
  },
});

function ContactForm() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [loading, setLoading] = useState(false)

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const toSuccess = () => {
    navigate('/contact-success');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await fetch(`https://test.korasview.com/send-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();
      toSuccess();
      console.log('Success:', data);
    } catch (error) {
      console.error('Error:', error);
    }
    setLoading(false);
  };
  
  return (

    <div className="container">
      {loading && <Loading/>}
      <div className="blurb">
        <h1>Contact</h1>
        <p>Get in touch to arrange a booking directly with us. Let us know when you would like to stay and we will reply as soon as possible.</p>
      </div>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            required
          ></textarea>
        </div>
      <ThemeProvider theme={theme}>
        <Button type="submit" Button variant="contained" endIcon={<Send/>}>
          Send
        </Button>
      </ThemeProvider>
    </form>
    </div>
  );
};

export default ContactForm;