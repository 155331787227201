import { useNavigate } from "react-router-dom"
import { Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Send } from '@mui/icons-material';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#344554',
          color: '#EFF2F6'
        },
      },
    },
  },
});

function ContactSuccess() {
  const navigate = useNavigate();

  const toHome = () => {
    navigate('/');
  }

  return (
    <div className="container">
      <div className="blurb"><h1>Thanks for getting in touch! We'll reply to you as soon as possible</h1></div>
        
        <ThemeProvider theme={theme}>
          <Button onClick={toHome} Button variant="contained" endIcon={<Send/>}>
            Back to Home
          </Button>
      </ThemeProvider>
    </div>
  )
}

export default ContactSuccess