import "../styles/header.css"
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Menu, MenuItem } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: '"Antic", sans-serif',
          fontSize: '24px',
          
        },
      },
    },
  },
});

function Header() {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toHome = () => {
		navigate('/');
    setAnchorEl(null);
	};

	const toContact = () => {
		navigate('/contact');
    setAnchorEl(null);
	};

	const toGallery = () => {
		navigate('/gallery');
    setAnchorEl(null);
	};

	const toReviews = () => {
		navigate('/reviews');
    setAnchorEl(null);
	};
  
  return (
    <ThemeProvider theme={theme}>
      <div className='header'>
          <button onClick={toHome}><h1>Koras View</h1></button>
          <div className="navigation">
              <button onClick={handleClick}><FontAwesomeIcon icon={faBars} size="2x"/></button>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={toHome}>Home</MenuItem>
                <MenuItem onClick={toGallery}>Gallery</MenuItem>
                <MenuItem onClick={toReviews}>Reviews</MenuItem>
                <MenuItem onClick={toContact}>Contact</MenuItem>
              </Menu>
          </div>
      </div>
    </ThemeProvider>
  );
}

export default Header;